import * as React from "react";
// import LoginComponent from "./LoginComponent";
import MainComponent from "./MainComponent";
import { connect } from "react-redux";
import RootState from "../states/RootState";
import { MuiThemeProvider } from "@material-ui/core";
import { appTheme } from "../AppTheme";

interface AppComponentProps {
    readonly hasSession: boolean;
}

const RootRoutes = (props: AppComponentProps) => {
    // if (props.hasSession) {
        return <MainComponent/>;
    // } else {
    //     return <LoginComponent />;
    // }
};

export class App extends React.Component<AppComponentProps> {
    public render(): any {
        return <MuiThemeProvider theme={appTheme}>
            <RootRoutes hasSession={this.props.hasSession}/>
        </MuiThemeProvider>;
    }
}

export default connect((state: RootState, ownProps: any) => ({
    hasSession: state.session !== null,
}), {})(App);
