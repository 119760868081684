import * as React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import "react-datepicker/dist/react-datepicker.css";

interface CreatePurchaseProps {
    readonly open: boolean;
    readonly classes?: any;

    onClose?(): void;
    onSubmit?(esid: string, mastercard: string, prouctId: string, expiresDate: number, comment: string): void;
}

interface CreatePurchaseState {
    readonly esidValue: string;
    readonly mastercardValue: string;
    readonly productIdValue: string;
    readonly commentValue: string;
    readonly expiresDateValue?: Date;
    readonly esidEmpty: boolean;
    readonly mastercardEmpty: boolean;
    readonly productIdEmpty: boolean;
    readonly open: boolean;
}

class EniqSoftwareAddEsidMastercardComponent extends React.Component<CreatePurchaseProps, CreatePurchaseState> {
    private readonly handleEsidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            esidEmpty: false,
            esidValue: event.target.value,
        });
    }

    private readonly handleMastercardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            mastercardEmpty: false,
            mastercardValue: event.target.value
        });
    }

    private readonly handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            commentValue: event.target.value
        });
    }

    private readonly handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private readonly handleSubmit = () => {
        if (this.state.mastercardValue == null ||
            this.state.productIdValue == null) {
            this.setState({
                esidEmpty: this.state.esidValue == null,
                mastercardEmpty: this.state.mastercardValue == null,
                productIdEmpty: this.state.productIdValue == null
            });
        } else {
            let expiresDate: number = 0;
            if (this.state.expiresDateValue != null) {
                expiresDate = this.state.expiresDateValue.getTime();
            }
            if (this.props.onSubmit) {
                this.props.onSubmit(
                    this.state.esidValue,
                    this.state.mastercardValue,
                    this.state.productIdValue,
                    expiresDate,
                    this.state.commentValue
                );
            }
        }
    }

    public componentWillMount(): void {
        this.setState({ open: this.props.open });
    }

    public render(): any {
        return <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Electronic System Number/Mastercard</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To create Electronic System Number/Mastercard record enter Electronic System Number and Mastercard value.<br/>
                    <b>Comment</b> is the optional field.<br/>
                </DialogContentText>
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.esidEmpty}
                    helperText={this.state.esidEmpty ? "Cannot be empty" : ""}
                    onChange={this.handleEsidChange}
                    autoFocus
                    margin="dense"
                    id="esid"
                    label="Electronic System Number"
                    type="text"
                    value={this.state.esidValue}
                    fullWidth
                />
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.mastercardEmpty}
                    helperText={this.state.mastercardEmpty ? "Cannot be empty" : ""}
                    onChange={this.handleMastercardChange}
                    autoFocus
                    margin="dense"
                    id="mastercard"
                    label="Mastercard"
                    type="text"
                    value={this.state.mastercardValue}
                    fullWidth
                />
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    onChange={this.handleCommentChange}
                    autoFocus
                    margin="dense"
                    id="comment"
                    label="Comment"
                    type="text"
                    value={this.state.commentValue}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose}> Cancel </Button>
                <Button classes={{ root: this.props.classes.trackButton }} onClick={this.handleSubmit}> Add License </Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    trackButton: {
        color: "#00a0de"
    },
    inputUnderline: {
        "&:after": {
            borderBottomColor: "#00a0de"
        },
        "&:error:after": {
            borderBottomColor: red[500]
        },
    },
    formLabelRoot: {
        "&$formLabelFocused" : { color: "#00a0de" },
    },
    formLabelFocused: {
    },
    erroredLabel: {
        "&$formLabelFocused" : { color: red[500] },
    }
};

export default withStyles(styles)(EniqSoftwareAddEsidMastercardComponent);
